import React from 'react'
// Components
import Content from 'components/Layout/Content'
import ArticlesSidebar from './ArticlesSidebar'
import ArticlesList from './ArticlesList'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { IArticleCard } from 'models/Article'
import { IContextWithPageNav } from 'models/Context'
// Styles
import * as styles from './ArticlesContainer.module.css'

type ArticlesContainerProps = {
    articles: IArticleCard[]
    featured: IArticleCard[]
    context: IContextWithPageNav
}

const ArticlesContainer: React.FC<ArticlesContainerProps> = ({
    articles,
    featured,
    context,
}) => {
    const { currentPage, totalPages } = context
    return (
        <section className={styles.articles}>
            <Content>
                <ArticlesList articles={articles} />
                <PageNav
                    to="/articles"
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </Content>
            <ArticlesSidebar featured={featured} />
        </section>
    )
}

export default ArticlesContainer

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
// Models
import { IImage } from 'models/Image'
// Styles
import * as styles from './FeaturedCard.module.css'

type FeaturedCardProps = {
    to: string
    title: string
    thumbnail: IImage
    date: string
}

const FeaturedCard: React.FC<FeaturedCardProps> = ({
    to,
    title,
    thumbnail,
    date,
}) => {
    return (
        <Link className={styles.featured_card} to={to}>
            <GatsbyImage
                className={styles.featured_card_thumbnail}
                image={thumbnail.gatsbyImageData}
                alt={title}
            />
            <header className={styles.featured_card_header}>
                <h3 className={styles.featured_card_title}>{title}</h3>
                <span className={styles.featured_card_date}>
                    <FontAwesomeIcon
                        className={styles.featured_card_icon}
                        icon={['far', 'calendar-alt']}
                    />
                    {date}
                </span>
            </header>
        </Link>
    )
}

export default FeaturedCard

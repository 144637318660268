import React from 'react'
import { getFormattedDate } from 'util/date'
// Components
import Sidebar, { SidebarSection } from 'components/Layout/Sidebar'
import PatreonWidget from 'components/UIElements/Patreon'
import FeaturedCard from 'components/UIElements/Card/FeaturedCard'
// Models
import { IArticleCard } from 'models/Article'
// Styles
import * as styles from './ArticlesSidebar.module.css'

type ArticlesSidebarProps = {
    featured: IArticleCard[]
}

const ArticlesSidebar: React.FC<ArticlesSidebarProps> = ({ featured }) => {
    return (
        <Sidebar>
            <SidebarSection
                className={styles.featured_articles}
                title="Destacado"
            >
                {featured.map((article) => {
                    const { title, slug, thumbnail, date } = article
                    const created_date = getFormattedDate(date)
                    return (
                        <article className={styles.featured_article}>
                            <FeaturedCard
                                key={slug}
                                title={title}
                                to={`/articles/${slug}`}
                                thumbnail={thumbnail}
                                date={created_date}
                            />
                        </article>
                    )
                })}
            </SidebarSection>
            <SidebarSection title="¡Apoyanos!">
                <PatreonWidget />
            </SidebarSection>
        </Sidebar>
    )
}

export default ArticlesSidebar

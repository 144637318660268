import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
import ArticlesContainer from 'components/Sections/Articles/ArticlesContainer'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IArticleCard } from 'models/Article'
import { IContextWithPageNav } from 'models/Context'

type ArticlesListPageProps = {
    articles: { nodes: IArticleCard[] }
    featured: { nodes: IArticleCard[] }
    pageSeo: ISeoQuery
}

const ArticlesListPage: React.FC<
    PageProps<ArticlesListPageProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const articles = data.articles.nodes
    const featured = data.featured.nodes
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }
    return (
        <Layout seo={seo}>
            <SectionHeader title={pageSeo.title} image={pageSeo.image} />
            <ArticlesContainer
                articles={articles}
                featured={featured}
                context={context}
            />
        </Layout>
    )
}

export default ArticlesListPage

export const pageQuery = graphql`
    query ArticlesListPage($limit: Int!, $skip: Int!) {
        articles: allContentfulArticles(
            limit: $limit
            skip: $skip
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                slug
                date
                excerpt {
                    text: excerpt
                }
                author {
                    name
                    username
                }
                categories {
                    name
                    slug
                    color
                }
                thumbnail {
                    gatsbyImageData(width: 750)
                }
            }
        }

        featured: allContentfulArticles(
            sort: { fields: date, order: DESC }
            filter: { featured: { eq: true } }
            limit: 3
        ) {
            nodes {
                title
                slug
                date
                thumbnail {
                    gatsbyImageData(width: 350)
                }
            }
        }

        pageSeo: contentfulSeo(slug: { eq: "news" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
